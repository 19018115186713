<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="page-title mb-2 pb-3 pt-3">
        <div class="page-title-icon">
          <con-icon-by-name iconName="list"></con-icon-by-name>
        </div>
        <h3 class="mb-0">Coverage List</h3>
      </div>
    </div>
  </div>
  <div class="card mt-2">
    <div class="card-header">Estimates Tier Isin</div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-4">
          <con-estimates-tier-isin-form (afterSave)="updateListing($event)"></con-estimates-tier-isin-form>
        </div>
        <div class="col-12 mt-3">
          <con-entity-list entityName="EstimatesTierIsin" [runUpdate]="reloadSubject"></con-entity-list>
        </div>
      </div>
    </div>
  </div>
</div>
