<ng-container *ngIf="!isEditMode; else editSection">
    <strong>
        Coverage
        <button class="btn btn-sm ml-1 px-0 no-shadow" [ngbTooltip]="'Edit coverage'" [placement]="'right'" (click)="toggleEditMode(true)" *ngIf="isEditable">
            <con-icon-by-name iconName="pencil"></con-icon-by-name>
        </button>
    </strong>
    <p *ngIf="coverageList.length">
        Analysts from
        <span *ngFor="let coverage of coverageList; let isLast=last">
            <a routerLink="../../../../entity/firm/{{coverage?.firm_id}}" target="_blank"> {{coverage?.firm_name}}<ng-container *ngIf="!isLast">,</ng-container> </a>
        </span>
        contributed to the poll.
    </p>
    <div class="p-4 col-12 mb-15 message-bg" *ngIf="!coverageList.length">
        No coverage data available.
    </div>
</ng-container>
<ng-template #editSection>
    <strong>Edit coverage</strong>
    <div class="message-bg my-2 p-2">
        <div class="chip m-1" *ngFor="let firm of editableList; let index = index">
            {{firm?.firm_name}}
            <i class="fa fa-close" (click)="removeFromList(index)" [ngbTooltip]="'Remove'"></i>
        </div>
        <div class="mt-2 mx-1">
            Choose a firm to add to the coverage list:
            <con-entity-search-field [entityName]="'Firm'" (onSelect)="addToList($event)" [fixedSearchParams]="fixedSearchParams"></con-entity-search-field>
            <button class="btn btn-success" (click)="saveCoverageList()" [disabled]="isSaving">
                <ng-container *ngIf="isSaving">
                    <div class="spinner-border spinner-border-sm mr-1"></div>
                </ng-container>
                Save
            </button>
            <button class="btn btn-secondary" (click)="toggleEditMode(false)">Cancel</button>
        </div>
        <div class="mt-2">
        </div>
    </div>

</ng-template>