<div class='container-fluid mb-3'>
  <div class='row'>
    <div class='col-12'>
      <div class='page-title mb-2 pb-3 pt-3'>
        <h3 class='mb-0'>Active Consensus Snapshot</h3>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isLoading; else elseContentSection">
    <con-loader></con-loader>
  </ng-container>
  <ng-template #elseContentSection>
    <div class='row'>
      <div class='col-12'>
        <div class="d-flex justify-content-start sticky-header non-sticky py-2">
          <h3 class="mb-0">
            <a routerLink="../../../../entity/company/{{company?.id}}"><span class="badge badge-dark">{{company?.name}}</span></a>
            <span *ngIf="company?.website"><a [href]=company?.website class="link-fontsize" target="_blank">{{getStripedWebsiteString(company?.website)}}</a></span>
          </h3>
          <div class="chip chip-inverse ml-auto">
            <con-avatar [userId]="company?.manager?.id" [size]="32"></con-avatar>
            {{company?.manager?.first_name}} {{company?.manager?.last_name}}
          </div>
        </div>
      </div>
    </div>
    <con-no-data *ngIf="currentSurveyDetails?.locked" count='0' class='approved-survey consensus-message'></con-no-data>
    <div class="alert ml-status-failed" *ngIf="showData && consensusData?.survey?.taxonomy_id === null">
      <con-icon-by-name iconName="warning"></con-icon-by-name> No taxonomy attached!
    </div>
    <div *ngIf="showData && consensusData?.survey?.taxonomy_id !== null">
      <div [ngClass]="{ 'is-locked': currentSurveyDetails?.locked}">
        <div class="card mb-2">
          <div class="card-header">
            <div class="d-flex justify-content-start align-items-center">
              <con-icon-by-name iconName="paperclip" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
              Active consensus snapshot for {{ consensusData?.survey?.date }} survey 
              <span class="ml-2" *ngIf="consensusData?.unit || consensusData?.quantity || consensusData?.survey?.currency"> ( 
                <span *ngIf="consensusData?.unit?.name"> {{consensusData?.unit?.name}} </span>
                <span *ngIf="consensusData?.unit?.name && (consensusData?.quantity?.name || consensusData?.survey?.currency?.code)"> / </span>
                <span *ngIf="consensusData?.quantity?.name"> {{consensusData?.quantity?.name}} </span>
                <span *ngIf="consensusData?.quantity?.name && consensusData?.survey?.currency?.code"> / </span>
                <span *ngIf="consensusData?.survey?.currency?.code"> {{consensusData?.survey?.currency?.code}} </span> )
              </span>
              <div class="card-header-toggle-individual ml-auto">
                <ng-container *ngIf="featuresEnabled[featureFlaggedKeys.estimates_manual_onboarding]">
                  <button class="btn btn-success  mr-2" type="button" (click)="downloadTemplate()" [disabled]="currentSurveyDetails?.locked">
                    <con-icon-by-name iconName="file-o text-white" class="mr-1"></con-icon-by-name>
                    Download template
                  </button>
                  <button class="btn btn-primary  mr-2" type="button" (click)="openImportModal()" [disabled]="currentSurveyDetails?.locked">
                    <con-icon-by-name iconName="cloud-upload text-white" class="mr-1"></con-icon-by-name>
                    Import
                  </button>
                </ng-container>
                <button class="btn btn-primary mr-2" type="button" [disabled]="currentSurveyDetails?.locked || !isAPISyncEnabled" *ngIf="latestSurveyId === consensusData?.survey?.id" (click)="fetchLatestConsensusManually()" [ngClass]="{ 'pointer-events-none': fetchConsensusLoading }">
                  <ng-container *ngIf="fetchConsensusLoading">
                    <ng-container *ngTemplateOutlet="buttonSpinner"></ng-container>
                  </ng-container>
                  Fetch latest consensus
                </button>
              </div>
            </div>
          </div>
          <div>
            <div class="p-4 col-12">
              <div class="d-flex align-items-center mb-2">
                <div class="w-100 d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center" *ngIf="consensusData?.consensus_type">
                    <strong>Consensus type</strong>
                    <div class="flex-1 d-flex align-items-center"> 
                      <span class="mr-2">:</span>
                      <ng-container *ngIf="!isTypeEditMode; else editType">
                        {{consensusData?.consensus_type || '-'}}
                        <button class="btn btn-sm ml-2 px-0 no-shadow" [ngbTooltip]="'Edit consensus type'" [placement]="'right'" (click)="toggleConsensusTypeEditMode(true)" *ngIf="!currentSurveyDetails?.locked">
                          <con-icon-by-name iconName="pencil"></con-icon-by-name>
                        </button>
                      </ng-container>
                      <ng-template #editType>
                        <div class="d-flex align-items-center">
                          <con-flexible-input [field]="formFields?.consensus?.consensus_type" [control]="consensusForm.controls['consensus_type']" [isSmallInput]="true"></con-flexible-input>
                          <button class="btn btn-sm btn-success mx-1" [disabled]="consensusFormLoading?.consensus_type || consensusForm?.controls['consensus_type']?.pristine" (click)="updateConensusType()">
                            <ng-container *ngIf="consensusFormLoading?.consensus_type">
                                <div class="spinner-border spinner-border-sm mr-1"></div>
                            </ng-container>
                            Save
                          </button>
                          <button class="btn btn-sm btn-secondary" (click)="toggleConsensusTypeEditMode(false)">Cancel</button>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                  <div class="d-flex" *ngIf="consensusData?.created_at">
                    <strong>Onboarded date</strong>
                    <div class="flex-1"><span class="mr-2">:</span>{{consensusData?.created_at | convertToTimezone:'yyyy-MM-DD'}}</div>
                  </div>
                </div>
              </div>
              <con-consensus-coverage [coverageList]="consensusData?.coverage" [surveyId]="currentSurveyDetails?.id" [isEditable]="!currentSurveyDetails?.locked"></con-consensus-coverage>
            </div>
            <div class="p-4 col-12 pt-0-imp pb-0-imp" *ngIf="showData">
              <form [formGroup]="consensusForm">
                <div class="row">
                  <div class="col-6">
                    <div class="full-width-custom">
                      <strong>Target Price <span *ngIf="consensusData?.survey?.currency">({{consensusData?.survey?.currency?.name}})</span></strong>
                    </div>
                    <div class="consensus-status-bg">
                      <table class="consensus-table table bg-white">
                        <thead class="thead-light">
                          <tr>
                            <th>Mean</th>
                            <th>Median</th>
                            <th>High</th>
                            <th>Low</th>
                            <th>No</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr formGroupName="target_price">
                            <td>
                              <input type="number" class="form-control-sm" formControlName="mean" [placeholder]="currentSurveyDetails?.locked ? '-' : ''"><br/>
                              <con-form-errors [control]="consensusForm.get('target_price').get('mean')"></con-form-errors>
                            </td>
                            <td>
                              <input type="number" class="form-control-sm" formControlName="median" [placeholder]="currentSurveyDetails?.locked ? '-' : ''"><br/>
                              <con-form-errors [control]="consensusForm.get('target_price').get('median')"></con-form-errors>
                            </td>
                            <td>
                              <input type="number" class="form-control-sm" formControlName="high" [placeholder]="currentSurveyDetails?.locked ? '-' : ''"> <br/>
                              <con-form-errors [control]="consensusForm.get('target_price').get('high')"></con-form-errors>
                            </td>
                            <td>
                              <input type="number" class="form-control-sm" formControlName="low" [placeholder]="currentSurveyDetails?.locked ? '-' : ''"><br/>
                              <con-form-errors [control]="consensusForm.get('target_price').get('low')"></con-form-errors>
                            </td>
                            <td>
                              <input type="number" class="form-control-sm" formControlName="amount" [placeholder]="currentSurveyDetails?.locked ? '-' : ''"> <br/>
                              <con-form-errors [control]="consensusForm.get('target_price').get('amount')"></con-form-errors>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <ng-container *ngIf="formErrors?.target_price">
                        <div class="alert alert-danger m-0 mt-1" *ngFor="let error of formErrors?.target_price">
                          {{ error }}
                        </div>
                      </ng-container>
                      <button *ngIf="!currentSurveyDetails?.locked" type="button" class="btn btn-success mt-15" (click)="onSubmit('target_price')" [disabled]="consensusFormLoading.target_price || consensusForm.get('target_price').pristine">
                        <ng-container *ngIf="consensusFormLoading.target_price">
                          <ng-container *ngTemplateOutlet="buttonSpinner"></ng-container>
                        </ng-container>
                        Save
                      </button>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="full-width-custom">
                      <strong>Recommendations / Outlook</strong>
                    </div>
                    <div class="consensus-status-bg">
                      <table class="consensus-table table bg-white">
                        <thead class="thead-light">
                          <tr>
                            <th>Buy</th>
                            <th>Overweight</th>
                            <th>Hold</th>
                            <th>Underweight</th>
                            <th>Sell</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr formGroupName="outlook">
                            <td>
                              <input type="number" class="form-control-sm" formControlName="buy" [placeholder]="currentSurveyDetails?.locked ? '-' : ''"><br/>
                              <con-form-errors [control]="consensusForm.get('outlook').get('buy')"></con-form-errors>
                            </td>
                            <td>
                              <input type="number" class="form-control-sm" formControlName="overweight" [placeholder]="currentSurveyDetails?.locked ? '-' : ''"><br/>
                              <con-form-errors [control]="consensusForm.get('outlook').get('overweight')"></con-form-errors>
                            </td>
                            <td>
                              <input type="number" class="form-control-sm" formControlName="hold" [placeholder]="currentSurveyDetails?.locked ? '-' : ''"><br/>
                              <con-form-errors [control]="consensusForm.get('outlook').get('hold')"></con-form-errors>
                            </td>
                            <td>
                              <input type="number" class="form-control-sm" formControlName="underweight" [placeholder]="currentSurveyDetails?.locked ? '-' : ''"><br/>
                              <con-form-errors [control]="consensusForm.get('outlook').get('underweight')"></con-form-errors>
                            </td>
                            <td>
                              <input type="number" class="form-control-sm" formControlName="sell" [placeholder]="currentSurveyDetails?.locked ? '-' : ''"><br/>
                              <con-form-errors [control]="consensusForm.get('outlook').get('sell')"></con-form-errors>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <ng-container *ngIf="formErrors?.outlook">
                        <div class="alert alert-danger m-0 mt-2" *ngFor="let error of formErrors?.outlook">
                          {{ error }}
                        </div>
                      </ng-container>
                      <button *ngIf="!currentSurveyDetails?.locked" type="button" class="btn btn-success mt-15" (click)="onSubmit('outlook')" [disabled]="consensusFormLoading.outlook || consensusForm.get('outlook').pristine">
                        <ng-container *ngIf="consensusFormLoading.outlook">
                          <ng-container *ngTemplateOutlet="buttonSpinner"></ng-container>
                        </ng-container>
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="p-4 col-12 pt-0-imp" *ngIf="showData && consensusData?.estimates === null">
              <div class="p-4 col-12 message-bg mt-15">
                No consensus period data available.
              </div>
            </div>
            <div class="p-4 col-12" *ngIf="showData && consensusData.estimates">
              <div *ngFor="let data of consensusData.estimates">
                <con-consenus-snapshot [periodData]="data" [surveyDetails]="currentSurveyDetails" [currency]="currentSurveyDetails?.currency" [consensusId]="consensusData?.id" (figureSaved)="updateFigureValue($event, data)" (refreshConsensus)="getConsenusData()"></con-consenus-snapshot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<ng-template #buttonSpinner>
  <div class="spinner-border spinner-border-sm mr-1"></div>
</ng-template>
