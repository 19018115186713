import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EntitiesService } from 'app/entities/services/entities.service';
import { EstimatesService } from 'app/estimates/services/estimates.service';
import { ApiSettings } from 'app/settings.class';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'con-consensus-coverage',
  templateUrl: './consensus-coverage.component.html',
  styleUrls: ['./consensus-coverage.component.scss']
})
export class ConsensusCoverageComponent implements OnDestroy {

  @Input() public coverageList: any[] = [];
  @Input() public surveyId: number;
  @Input() public isEditable: boolean = true;

  private componentDestroyed$: Subject<boolean> = new Subject();  

  public isEditMode: boolean = false;
  public editableList: any[] = [];
  public isSaving: boolean = false;
  public fixedSearchParams: any = {id: ''};

  constructor(
    private toastr: ToastrService,
    private estimatesService: EstimatesService,
    private entityService: EntitiesService
  ) { }

  public ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  public toggleEditMode(status: boolean): void {
    this.isEditMode = status;
    this.editableList = status ? [...this.coverageList] : [];
    this.setSearchParams();
  }

  public removeFromList(index: number): void {
    this.editableList.splice(index, 1);
    this.setSearchParams();

  }

  public addToList(firm: any): void {
    const { id: firm_id, name: firm_name, ...rest } = firm;
    this.editableList.push({ ...rest, firm_id, firm_name });
    this.setSearchParams();
  }

  saveCoverageList(): void {
    this.isSaving = true;
    const payload = {
      firm_ids: this.editableList.map((item) => item.firm_id)
    }
    this.estimatesService.editCoverageList(this.surveyId, payload).pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
      this.toastr.success('Coverage list updated successfully');
      this.coverageList = this.editableList;
      this.isSaving = false;
      this.toggleEditMode(false);
    }, (error) => {
      this.isSaving = false;
      if(error.isValueError()) {
        this.toastr.error(this.entityService.getFirstErrorForToaster(error.data));
      } else {
        this.toastr.error(ApiSettings.INTERNAL_SERVER_ERROR);
      }
    });
  }

  private setSearchParams(): void {
    this.fixedSearchParams = {
      id: this.editableList.map((item) => `f:neq:${item.firm_id}`).join('&')
    }
  }

}
