<div class="alert ml-status-failed mb-0" *ngIf="taxonomyListingData === null && surveyEntity?.locked; else elseTaxonomySection">
  <con-icon-by-name iconName="warning"></con-icon-by-name> No taxonomy attached!
</div>
<ng-template #elseTaxonomySection>
  <con-loader *ngIf="isLoadingModules"></con-loader>
  <div class="card mb-15" *ngIf="taxonomyListingData">
    <div class="card-header">
      Taxonomy <ng-container *ngIf="surveryDate">for {{surveryDate}} survey</ng-container>
    </div>
    <div class="card-body">
      <div class="taxonomy-table">
        <div class="table-row table-head">
          <div class="table-cell">Name</div>
          <div class="table-cell">Locked</div>
          <div class="table-cell">Created At</div>
          <div class="table-cell">Updated At</div>
        </div>
        <div class="table-row">
          <div class="table-cell">{{taxonomyListingData.name}}</div>
          <div class="table-cell">{{taxonomyListingData.locked}}</div>
          <div class="table-cell"><con-date-shower [date]="taxonomyListingData?.created_at"></con-date-shower></div>
          <div class="table-cell"><con-date-shower [date]="taxonomyListingData?.updated_at"></con-date-shower></div>
        </div>
      </div>
    </div>
  </div>
  <div class="card" *ngIf="showCreateMode || showEditMode">
    <div class="card-header">
      <span *ngIf='showCreateMode'>Create taxonomy</span> 
      <span *ngIf='showEditMode && !surveyEntity?.locked'>Edit taxonomy modules</span>
      <span *ngIf='showEditMode && surveyEntity?.locked'>View taxonomy modules</span>
    </div>
    <div class="card-body">
      <con-loader *ngIf="isLoadingTaxonomy"></con-loader>
      <ng-container *ngIf="!isLoadingTaxonomy">
        <div class="d-flex justify-content-between align-items-end" *ngIf="!surveyEntity?.locked">
          <div class="form-group search-field mb-0" *ngIf="!selectedPrefill">
            <label class="form-label"><small>Choose taxonomy to {{showEditMode ? 'reset' : 'prefill'}}</small></label>
            <con-entity-search-field (onSelect)="showPrefilChangeConfirmModal($event)" entityName="Taxonomy" [fixedSearchParams]="{taxonomy_type:'Estimates'}" [fixedOptions]="fixedOptions" [isSmallSizeInput]="true" [showOnlyFixedoptions]="false" [labelKey]="'name'"></con-entity-search-field>
          </div>
          <div class="form-group search-field mb-0" *ngIf="selectedPrefill">
            <label class="form-label"><small>Prefilled taxonomy</small></label>
            <div class="alert alert-sm alert-success alert-dismissible mb-0" role="alert">
                {{selectedPrefill?.name}}
                <button type="button" class="close no-shadow" data-dismiss="alert" aria-label="Close" (click)="clearPrefillSelection()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
          </div>
          <div class="pl-2">
            <button class="btn btn-primary" (click)="addParentModule()" *ngIf="!surveyEntity?.locked"> Add Parent Module </button>
          </div>
        </div>
        <div class="mt-15">
          <div class="taxonomy-header">
              <div class="left-header">
                  <strong>Modules</strong>
              </div>
              <div class="right-header" [ngClass]="{ 'locked-header': surveyEntity?.locked }">
                  <strong>Alias</strong>
              </div>
          </div>
          <div class="clearfix"></div>
          <form [formGroup]="formArray" (ngSubmit)="saveCustomTaxonomy()">
              <div [dragula]="surveyEntity?.locked ? null : 'parents'">
                  <ng-container *ngFor="let form of formArray.controls; let i = index;">
                      <div formGroupName="{{i}}" [id]="i">
                          <div class="rcm parentHandle" [ngClass]="form.errors? 'rcm-error-height-adj' : ''">
                              <div class="btn-w pull-left">
                                  <button type="button" class="btn-w btn no-shadow" *ngIf="form.controls.children.length" (click)="toggleChildrenDisplay(form)">
                                      <con-icon-by-name [iconName]="form.controls.expandChildren.value ? 'angle-down' : 'angle-right'"></con-icon-by-name>
                                  </button>
                              </div>
                              <div class="w-auto select-pos">
                                  <ng-select class='"w-250' formControlName="item" #selectDropdown
                                      placeholder="Select or add an item..." notFoundText="Type to add value..."
                                      typeToSearchText="Type to add value..." (change)="updateAlias(form, $event)"
                                      [compareWith]="compareFn" [clearable]="false"
                                      [items]="nameOptions | filterSelected:selectedNames:form.get('item').value"
                                      bindLabel="name" bindValue="value"
                                      [addTag]="form.get('allowTags')?.value ? addTag : null">
                                  </ng-select>
                                  <small *ngIf="form.errors" class="mat-error">{{form.errors[0]}}</small>
                              </div>

                              <div class="taxonomy-btn-ctrls" *ngIf="!surveyEntity?.locked">
                                  <button type="button" class="btn btn-danger pull-right" (click)="removeParent(i)">
                                      <con-icon-by-name iconName="minus-circle"></con-icon-by-name>
                                  </button>
                                  <button type="button" class="btn btn-primary pull-right mr-15"
                                      (click)="addChild(form)">
                                      <con-icon-by-name iconName="plus-circle"></con-icon-by-name>
                                  </button>
                              </div>
                              <div class="tree-input-alias-width pull-right">
                                  <input formControlName="alias" [placeholder]="form?.value?.item?.name || 'Alias'" class="bg-custom form-control ">
                              </div>
                          </div>
                          <div [dragula]="surveyEntity?.locked ? null : 'children'" class="drop-area">
                              <ng-container *ngIf="form.controls.expandChildren.value">
                                  <ng-container formArrayName="children" *ngIf="form.controls.children">
                                      <div class="tree-row-data" *ngFor="let childForm of form.controls.children.controls; let j = index;" [formGroupName]="j" [id]="j">
                                          <div class="rcm childHandle" [ngClass]="childForm.errors? 'rcm-error-height-adj' : ''">
                                              <div class="btn-w pull-left">&nbsp;</div>
                                              <div class="w-auto select-pos">
                                                  <ng-select class='"w-250' formControlName="item"
                                                      placeholder="Select or add an item..."
                                                      notFoundText="Type to add value..."
                                                      typeToSearchText="Type to add value..."
                                                      (change)="updateAlias(childForm, $event)"
                                                      [compareWith]="compareFn" [clearable]="false"
                                                      [items]="nameOptions | filterSelected:selectedNames:childForm.get('item').value"
                                                      bindLabel="name" bindValue="value"
                                                      [addTag]="childForm.get('allowTags')?.value ? addTag : null">
                                                  </ng-select>
                                                  <small *ngIf="childForm.errors" class="mat-error">{{childForm.errors[0]}}</small>
                                              </div>
                                              <div class="taxonomy-btn-ctrls" *ngIf="!surveyEntity?.locked">
                                                  <button type="button" class="btn btn-danger pull-right" (click)="removeChild(form, j)">
                                                      <con-icon-by-name iconName="minus-circle"></con-icon-by-name>
                                                  </button>
                                                  <button type="button" class="btn btn-primary pull-right mr-15" (click)="addGrandchild(childForm)">
                                                      <con-icon-by-name iconName="plus-circle"></con-icon-by-name>
                                                  </button>
                                              </div>
                                              <div class="tree-input-alias-width pull-right child-mr-adj">
                                                  <input formControlName="alias" [placeholder]="childForm?.value?.item?.name || 'Alias'" class="bg-custom form-control">
                                              </div>
                                          </div>
                                          <div [dragula]="surveyEntity?.locked ? null : 'grandChildren'" class="drop-area">
                                              <ng-container formArrayName="children" *ngIf="childForm.controls.children">
                                                  <div class="tree-row-data" *ngFor="let grandchildForm of childForm.controls.children.controls; let k = index;" [formGroupName]="k">
                                                      <div class="rcm" [ngClass]="grandchildForm.errors? 'rcm-error-height-adj' : ''">
                                                          <div class="btn-w pull-left">&nbsp;</div>
                                                          <div class="w-500 select-pos">
                                                              <ng-select class='"w-250' formControlName="item"
                                                                  placeholder="Select or add an item..."
                                                                  notFoundText="Type to add value..."
                                                                  typeToSearchText="Type to add value..."
                                                                  (change)="updateAlias(grandchildForm, $event)"
                                                                  [compareWith]="compareFn" [clearable]="false"
                                                                  [items]="nameOptions | filterSelected:selectedNames:grandchildForm.get('item').value"
                                                                  bindLabel="name" bindValue="value"
                                                                  [addTag]="grandchildForm.get('allowTags')?.value ? addTag : null">
                                                              </ng-select>
                                                              <small *ngIf="grandchildForm.errors" class="mat-error">{{grandchildForm.errors[0]}}</small>
                                                          </div>
                                                          <div class="taxonomy-btn-ctrls" *ngIf="!surveyEntity?.locked">
                                                              <button type="button" class="btn btn-danger pull-right" (click)="removeGrandchild(childForm, k)">
                                                                  <con-icon-by-name iconName="minus-circle"></con-icon-by-name>
                                                              </button>
                                                          </div>
                                                          <div class="tree-input-alias-width pull-right child-mr-adj">
                                                              <input formControlName="alias" [placeholder]="grandchildForm?.value?.item?.name || 'Alias'" class="bg-custom form-control">
                                                          </div>
                                                      </div>
                                                  </div>
                                              </ng-container>
                                          </div>
                                      </div>
                                  </ng-container>
                              </ng-container>
                          </div>
                      </div>
                  </ng-container>
              </div>
              <div class="save-position">
                  <div class="form-group">
                      <label>
                          <small>
                              Taxonomy Name
                          </small>
                      </label>
                      <input type="text" [formControl]="taxonomyName" placeholder="Custom Taxonomy Name" class="form-control tree-input-alias-width child-mr-adj">
                      <small *ngIf='taxonomyName.errors' class="mat-error">{{taxonomyName.errors.serverError}}</small>
                  </div>
                <div *ngIf="!surveyEntity?.locked"> 
                    <button type="submit" class="btn btn-success mt-15" [disabled]="isSavingTaxonomy"> 
                        <ng-container *ngIf="isSavingTaxonomy">
                            <div class="spinner-border spinner-border-sm mr-1"></div>
                        </ng-container>
                        <span *ngIf="showCreateMode"> Create </span>
                        <span *ngIf="showEditMode"> Update </span>
                        Taxonomy
                    </button>
                </div>
                  <div *ngIf="errorResponse">
                      <small class="mat-error">{{ errorResponse?.message }}</small>
                  </div>
              </div>
          </form>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

