<div class="card">
    <div class="card-header d-flex justify-content-start" (click)="isFormExpanded = !isFormExpanded">
        <con-icon-by-name [iconName]="iconName" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
        New Estimates Tier Isin
        <div class="card-header-toggle ml-auto">
            <con-icon-by-name iconName="angle-right" [mutations]="isFormExpanded ? ['fw', 'lg', 'rotate-90'] : ['fw', 'lg']"></con-icon-by-name>
        </div>
    </div>
    <div class="card-body" *ngIf="isFormExpanded">
        <ng-container *ngIf="!isLoadingInitialData; else loader">
            <span *ngIf="!canStore; else formContent"> You are not allowed to create new entities of this type </span>
            <ng-template #formContent>
                <div role="form" [formGroup]="estimatesTierIsinForm">
                    <div class="form-group">
                        <label><small>Company</small></label>
                        <con-entity-search-field *ngIf="!choosenCompany; else companyDetails" [entityName]="'Company'" (onSelect)="onChoosingCompany($event)"></con-entity-search-field>
                        <ng-template #companyDetails>
                            <div class="alert alert-info alert-dismissible mb-0">
                                <button type="button" class="close" aria-label="Close" (click)="clearChoosenCompany()"> <span aria-hidden="true">&times;</span> </button>
                                <a class="alert-link-icon mr-1" [routerLink]="['/entity/company', choosenCompany?.id]" placement="top" container="body" [ngbTooltip]="'Go to company'">
                                    <con-icon-by-name [iconName]="'building'" [mutations]="['fw']"></con-icon-by-name>
                                </a>
                                <strong>{{choosenCompany?.name}}</strong>
                            </div>
                        </ng-template>
                        <con-form-errors [control]="estimatesTierIsinForm.controls['company_id']" [extra]="APIErrors?.fields?.company_id"></con-form-errors>
                    </div>
                    <div class="form-group">
                        <label><small>{{formFieldConfig?.isin?.label}}</small></label>
                        <div class="d-flex">
                            <ng-select 
                                class="w-100" 
                                [items]="companyISINs"
                                bindLabel="isin"
                                bindValue="isin"
                                [clearable]="false"
                                [readonly]="isLoadingIsins"
                                formControlName="isin"
                                [multiple]="false"
                                [notFoundText]="choosenCompany ? 'No ISINs found for this company' : 'Please choose company first to list ISINs'">
                            </ng-select>
                            <div class="input-group-append" *ngIf="isLoadingIsins">
                              <span class="input-group-text">
                                <con-loader></con-loader>
                              </span>
                            </div>
                        </div>
                        <con-form-errors [control]="estimatesTierIsinForm.controls['isin']" [extra]="APIErrors?.fields?.isin"></con-form-errors>
                    </div>
                    <div class="form-group">
                        <label><small>{{formFieldConfig?.fetch_from_collector?.label}}</small></label>
                        <con-flexible-input [field]="formFieldConfig?.fetch_from_collector" [control]="estimatesTierIsinForm.controls['fetch_from_collector']"></con-flexible-input>
                        <con-form-errors [control]="estimatesTierIsinForm.controls['fetch_from_collector']" [extra]="APIErrors?.fields?.fetch_from_collector"></con-form-errors>
                    </div>
                    <div class="alert alert-danger" *ngFor="let message of APIErrors?.general">
                        {{ message }}
                    </div>
                    <button type="submit" class="btn btn-success" (click)="onFormSubmit()" [disabled]="isSaving">
                        <div class="spinner-border spinner-border-sm mr-1" *ngIf="isSaving"></div>
                        Save
                    </button>
                    <button type="button" class="btn btn-warning" (click)="resetForm()">Revert</button>
                </div>
            </ng-template>
        </ng-container>
        <ng-template #loader>
            <con-loader></con-loader>
        </ng-template>
    </div>
</div>