import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ProfileService } from 'app/auth/services/profile.service';
import { EntitiesService, EntityDescription } from 'app/entities/services/entities.service';
import { ValidatorConversionService } from 'app/entities/services/validator-conversion.service';
import { ApiSettings } from 'app/settings.class';
import { sentenceCase } from 'change-case';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'con-estimates-tier-isin-form',
  templateUrl: './estimates-tier-isin-form.component.html',
  styleUrls: ['./estimates-tier-isin-form.component.scss']
})
export class EstimatesTierIsinFormComponent implements OnInit {

  @Output() afterSave: EventEmitter<any> = new EventEmitter();

  private componentDestroyed$: Subject<boolean> = new Subject();
  private entityDescription: EntityDescription;

  public APIErrors: any;
  public canStore: boolean;
  public companyISINs: any[] = [];
  public choosenCompany: any;
  public entityName = 'EstimatesTierIsin';
  public estimatesTierIsinForm: FormGroup;
  public formFieldConfig: any;
  public iconName: string;
  public isFormExpanded = true;
  public isLoadingInitialData = true;
  public isLoadingIsins = false;
  public isSaving = false;

  constructor(
    private entityService: EntitiesService,
    private profileService: ProfileService,
    private validatorService: ValidatorConversionService,
    private toasterService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.initInitialConfig();
  }

  private initInitialConfig(): void {
    this.canStore = this.profileService.can('store-' + this.entityName);
    this.entityService.getEntityDescriptionByEntityName(this.entityName).pipe(take(1)).subscribe((entityDescription: EntityDescription) => {
      this.entityDescription = entityDescription;
      this.iconName = entityDescription.getIcon()
      this.prepareForm();
      this.isLoadingInitialData = false;
    });
  }

  private prepareForm(): void {
    this.formFieldConfig = this.entityDescription.getFieldsObject();
    const fieldValidators: any = this.validatorService.getValidatorsForFields(this.formFieldConfig);
    this.estimatesTierIsinForm = new FormGroup({
      isin: new FormControl(null, fieldValidators.isin),
      company_id: new FormControl('', fieldValidators.company_id),
      fetch_from_collector: new FormControl(false, fieldValidators.fetch_from_collector)
    });
  }

  public onChoosingCompany(optionChoosen: any): void {
    this.choosenCompany = optionChoosen;
    this.estimatesTierIsinForm.controls['company_id'].setValue(optionChoosen.id);
    this.estimatesTierIsinForm.controls['company_id'].markAsTouched();
    this.isLoadingIsins = true;
    this.entityService.fetchDistinctISINs(optionChoosen?.id).pipe(take(1)).subscribe((ISINs: any) => {
      this.companyISINs = ISINs;
      this.isLoadingIsins = false;
    },
    () => {
      this.isLoadingIsins = false;
    });
  }

  public clearChoosenCompany(): void {
    this.estimatesTierIsinForm.controls['company_id'].setValue('');
    this.estimatesTierIsinForm.controls['isin'].setValue('');
    this.choosenCompany = null;
    this.companyISINs = [];
  }

  public onFormSubmit(): void {
    this.APIErrors = null;
    this.estimatesTierIsinForm.markAllAsTouched();
    if(this.estimatesTierIsinForm.invalid) return;
    this.isSaving = true;
    this.entityService.saveEntity(this.entityName, this.estimatesTierIsinForm.value).pipe(takeUntil(this.componentDestroyed$)).subscribe((response: any) => {
      this.afterSave.emit(response);
      this.toasterService.success('Saved successfully', sentenceCase(this.entityName));
      this.isSaving = false;
      this.resetForm();
    },
    (error: any) => {
      this.isSaving = false;
      if (error.isValueError()) {
        this.APIErrors = error.reformatBEValidationErrors(['isin', 'company_id', 'fetch_from_collector']);
      } else {
        this.toasterService.error(ApiSettings.INTERNAL_SERVER_ERROR);
      }
    });
  }

  public resetForm(): void {
    this.clearChoosenCompany();
    this.prepareForm();
  }

}
